/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "~@ng-select/ng-select/themes/default.theme.css";



.mat-mdc-form-field.mat-form-field-appearance-fill.mat-form-field-disabled {
    opacity: 1 !important; /* Remove the grayout effect */
}

.grid-five-image-button {
    grid-template-columns: 5% 30% 25% 25% 5%;
}

.grid-five-rows-button {
    grid-template-columns: 25% 30% 25% 25% 5%;
}

.grid-six-rows-button {
    grid-template-columns: 25% 30% 25% 3% 3%;
}

.grid-six-rows-button-cities {
    grid-template-columns: 10% 10% 55% 10% 3% 3%;
}

.grid-five-rows-destinations {
    grid-template-columns: auto 48px 40px;

    @screen sm {
        grid-template-columns: auto 48px 40px;
    }

    @screen md {
        grid-template-columns: auto 48px 40px;
    }

    @screen lg {
        grid-template-columns: 300px  400px auto 112px 72px;
    }
}

.mat-form-field .mat-form-field-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.special-offers-grid {
    grid-template-columns: auto 56px;

    @screen sm {
        grid-template-columns: auto 224px 72px;
    }

    @screen md {
        grid-template-columns: auto 224px 112px 72px;
    }

    @screen lg {
        grid-template-columns: auto 448px 112px 96px 96px;
    }
}

.custom-quill .ql-editor {
    min-height: 15rem; /* Adjust this value as needed */
    overflow-y: auto;
}

.full-height-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.full-height-tabs .mat-tab-body {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.mat-mdc-tab-body-wrapper {
    height: 120%;
}

input.no-arrows::-webkit-inner-spin-button,
input.no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.no-arrows {
    -moz-appearance: textfield;
}

.hide-required-marker .mat-mdc-form-field-required-marker {
    display: none;
}

// Override ng-select styles with Tailwind classes
.ng-select {
    @apply h-12 dark:bg-black dark:bg-opacity-5 dark:border-slate-500 z-9999;
}
//
.ng-select .ng-select-container {
    @apply h-12 dark:bg-black dark:bg-opacity-5 dark:text-white dark:border-slate-500;
}
.ng-select.ng-select-single .ng-select-container {
    @apply h-12 dark:bg-black dark:bg-opacity-5 dark:text-white dark:border-slate-500;
}
//
.ng-select .ng-input {
    @apply h-auto pl-4 dark:bg-black dark:bg-opacity-5 dark:text-white dark:border-slate-500;
}
//
.ng-select .ng-dropdown-panel {
    @apply dark:bg-black dark:bg-opacity-5 dark:border-slate-500 -z-99999;

    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    width: 100% !important;
}
//
.ng-select .ng-option {
    @apply dark:bg-black dark:text-white;
}
//
.ng-select .ng-option-selected {
    @apply dark:bg-black dark:bg-opacity-5 dark:text-white;
}

.ql-custom-gold-text::before {
    content: 'Gold Text';
    font-family: sans-serif;
}

.portal-welcome {
    background-color: #1a1c2c;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.portal-welcome h1 {
    font-size: 2em;
    color: #f1c40f;
    margin-bottom: 20px;
}

.portal-welcome h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #f1c40f;
}

.portal-welcome ul {
    list-style-type: disc;
    margin: 20px 0;
    padding-left: 20px;
}

.portal-welcome ul li {
    margin-bottom: 10px;
}

.portal-welcome strong {
    color: #f1c40f;
}
