/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;


@layer utilities {
    @screen lg {
        .my-float-right {
            float: right;
        }
    }
}
